<template>
  <DashboardTemplate>
    <Layout>
      <div class="min-height-screen-120 text-center pd-5 position-relative">
        <div class="position-absolute" style="top: 15%; left: 0; right: 0">
          <h3 class="mg-0">ประเมินความพร้อมใหม่?</h3>
          <h3 class="mg-t-4">คุณต้องการยืนยันการประเมินใหม่ ?</h3>
          <p class="text-grey mg-y-6">
            หากคุณต้องการประเมินความพร้อมใหม่ข้อมูลที่มีอยู่<span
              class="text-danger"
              ><u>จะถูกลบแบบถาวร</u></span
            >
          </p>
          <div class="is-flex js-between ai-center mg-t-1">
            <a @click="$router.go(-1)" class="text-grey-light font-18"
              >ยกเลิก</a
            >
            <div>
              <el-button type="primary" class="wid180" @click="reset()"
                >ยืนยัน</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDashboardTemplate";
export default {
  components: {
    DashboardTemplate,
    Layout
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    }
  },
  methods: {
    reset ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`new/assessment`)
        .then(res => {
          //this.getUserById();
          if (res.data.success) {
            this.$store.commit("SET_USER_OBJECT", {
              key: "recommendStatus",
              value: 2
            });
            this.$message({
              message: "อัปเดตสำเร็จ",
              type: "success"
            });
          }
        })
        .catch(e => {
          console.log(`new/assessment error`, e);
        })
        .finally(() => {
          this.$router.push("/factor").catch(err => {});
        });
    },
    getUserById ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get("user")
        .then(async res => {
          if (res.data.success) {
            await this.$store.commit(
              "SET_USER",
              JSON.parse(JSON.stringify(res.data.obj))
            );
          }
        })
        .finally(() => {
          this.$router.push("/factor").catch(err => {});
        });
    }
  }
};
</script>